/* eslint no-console:0 */
import 'core-js/stable' // ES6 polyfills
import 'regenerator-runtime/runtime' // more polyfills
import 'dom4' // DOM API polyfills

import 'font-awesome-sass-loader'
import 'bootstrap/js/dist/dropdown'
import 'unpoly/dist/unpoly.js'
import 'unpoly/dist/unpoly-bootstrap3.js'
import 'unpoly/dist/unpoly.css'

// JavaScript
let webpackContext = require.context('../javascripts', true, /\.js(\.erb)?$/)
for(let key of webpackContext.keys()) { webpackContext(key) }

// Fonts
require.context('../fonts', true, /\.(?:woff2|woff)$/)

// Images
require.context('../images', true, /\.(?:png|jpg|gif|ico|svg)$/)

// Stylesheets
import '../stylesheets/custom_bootstrap'
import '../stylesheets/brand_font'
import '../stylesheets/theme'

require.context('../stylesheets/blocks', true, /\.s[ac]ss(\.erb)?$/)
require.context('../stylesheets/ext', true, /\.s[ac]ss(\.erb)?$/)
