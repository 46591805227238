up.compiler('[content-editable-toggle]', (element, target) => {
  let targetElement = document.querySelector(target)

  function toggleContentEditable(event, input, data) {
    if (up.element.booleanAttr(targetElement, 'contenteditable')) {
      targetElement.removeAttribute('contenteditable')
    } else {
      up.element.setAttrs(targetElement, { contenteditable: true })
    }
  }

  up.on(element, 'change', toggleContentEditable)
})
