up.compiler('input[type="number"]', (element) => {

  // Number inputs allow incrementing/decrementing with arrow keys, and support exponential notation
  // (in some browsers even regular letters). We want neither.
  element.addEventListener('keydown', (event) => {
    const key = event.key
    if (key === 'ArrowUp' || key === 'ArrowDown' || key.length === 1 && !event.ctrlKey && !event.altKey && !key.match(/[0-9\-+,.]/)) {
      event.preventDefault()
    }
  })

  // Some browser (at least Firefox) will increment/decrement a number when using the mouse's scroll wheel
  // above a focused number input. Since this can lead to users changing values by accident, we want to avoid that.
  element.addEventListener('wheel', (event) => {
    if (element.matches(':focus')) {
      event.preventDefault()
    }
  })

})
