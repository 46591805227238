up.compiler('.flashes', (element) => {

  // The .flashes container is using `position: fixed` where the right anchor is the right end of the window scrollbar
  // when a modal is open. We simply copy body's padding (received via Unpoly) to fix that.
  //
  // Element is updated on every request, so no need to listen to up:modal:open or similar.
  //
  up.util.task(() => {
    element.style.paddingRight = document.body.style.paddingRight
  })

})
