up.compiler('[navbar-toggler]', (element) => {

  function onClick() {
    let drawerNavbar = document.createElement('div')

    drawerNavbar.innerHTML = getNavbar().innerHTML
    drawerNavbar.classList.add('drawer-navbar')
    drawerNavbar.querySelectorAll('.navbar-nav').forEach((item) => {
      item.setAttribute('class', 'nav nav-pills flex-column')
    })

    up.modal.extract('.drawer-navbar', drawerNavbar.outerHTML, { flavor: 'drawer', link: element })
  }

  function updateVisibility() {
    up.element.toggleClass(element, 'invisible', !getNavbar())
  }

  function getNavbar() {
    return document.getElementById(element.getAttribute('aria-controls'))
  }

  element.addEventListener('click', onClick)
  up.on('up:history:pushed', updateVisibility)
  updateVisibility()

})
