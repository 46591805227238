up.compiler('[user-menu-toggle]', (element, data) => {

  const userMenuIdentifier = data.userMenuIdentifier

  function onClick() {
    let userMenu = document.querySelector(userMenuIdentifier)
    let drawer = document.createElement('div')

    drawer.innerHTML = userMenu.innerHTML
    drawer.classList.add('drawer-user-menu', 'nav', 'nav-pills', 'flex-column')

    up.modal.extract('.drawer-user-menu', drawer.outerHTML, { flavor: 'drawer', link: element })
  }

  element.addEventListener('click', onClick)

})
