const DEFAULT_TARGET = '.content'

up.macro('[modal-link]', (link) => {
  let target = link.getAttribute('modal-link') || DEFAULT_TARGET
  let attrs = {
    'up-modal': target,
    // 'up-preload': '',
    'up-instant': '',
    'up-history': 'false',
  }

  // It feels wrong for a button
  if (link.matches('.btn:not(.btn-link)')) {
    delete attrs['up-instant']
  }

  up.element.setAttrs(link, attrs)
})
