import { inTestEnvironment } from '../util/environment'

up.compiler('.form-check', (element) => {
  let label = element.querySelector('label')
  let input = element.querySelector('input[type="checkbox"], input[type="radio"]')

  if (label && input) {
    input.classList.add('custom-control-input')
    label.classList.add('custom-control-label')
    element.classList.add('custom-control', `custom-${input.type}`)
    if (inTestEnvironment()) input.classList.add('-test')
  }

  if (element.matches('.form-check-inline')) {
    element.classList.remove('form-check-inline', 'custom-control-inline')
  }

  element.addEventListener('click', (event) => {
    // When the container element is clicked directly (i.e. the gap between control and label), nothing would happen.
    // The user actually wanted to click the control, so we do that.
    if (element === event.target) input.click()
  })
})
