up.compiler('input[type=file]', (element) => {
  let container = element.closest('.form-group')

  element.addEventListener('change', function() {
    let filename = String(this.value)
    let basename = filename.replace(/^.*[\\\/]([^\\\/]+)$/, '$1')

    container.querySelector('.custom-file-label').innerText = basename

    if ( /\.(jpe?g|png)/.test(basename) && element.matches('.image') ) {
      let file = element.files[0]
      readImageAsDataURL(file).then((dataURL) => {
        let preview = container.querySelector('.custom-file-preview')
        preview.src = dataURL
        preview.classList.remove('d-none')
      })
    }
  })

  function readImageAsDataURL(file) {
    return new Promise((resolve, reject) => {
      let reader = new FileReader()
      reader.addEventListener('load', () => {
        resolve(reader.result)
      })
      reader.readAsDataURL(file)
    })
  }
})
