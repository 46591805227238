up.compiler('[loading-indicator]', (element) => {
  let body = document.body
  let timeout = undefined
  let transitionDelay = 300
  let slow = false

  function move() {
    let step = 0
    let width = 33

    function moveNext() {
      step++
      if (width < 90) width++
      moveTo(width)
      timeout = setTimeout(moveNext, transitionDelay + step * 30)
    }
    moveNext()
  }

  function moveTo(width) {
    element.style.width = `${width}vw`
  }

  function finish() {
    clearTimeout(timeout)
    moveTo(100)
  }

  function show() {
    element.classList.add('-visible')
    element.style.transition = `width ${transitionDelay}ms ease-out`
    body.classList.add('-loading-indicator')
  }

  function hide() {
    element.style.transition = `width 0 ease-out`
    element.classList.remove('-visible')
    body.classList.remove('-loading-indicator')
    moveTo(0)
  }

  function onSlow() {
    if (slow) return

    slow = true
    show()
    move()
  }

  function onRecover() {
    slow = false

    finish()
    setTimeout(hide, transitionDelay)
  }

  return [
    up.on('up:proxy:slow', onSlow),
    up.on('up:proxy:recover', onRecover),
  ]
})
