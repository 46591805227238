import { inTestEnvironment } from './util/environment'
import { getCsrfParam, getCsrfToken } from './util/csrf'

up.protocol.config.csrfParam = getCsrfParam
up.protocol.config.csrfToken = getCsrfToken

up.form.config.observeDelay = 100
up.viewport.config.revealPadding = 100

if (inTestEnvironment()) {
  // Disable animations globally so Capybara doesn't see duplicate elements during transitions
  up.motion.config.enabled = false
  // When revealing elements, don't animate the scrolling
  up.viewport.config.duration = 0
  up.proxy.config.slowDelay = 800
}

up.fragment.config.fallbacks = ['.page', 'body']

up.radio.config.hungry = [
  '[up-hungry]',
  'head meta[name="robots"]',
  'head meta[name="description"]',
  'head meta[property*="og:"]',
  'head link[rel="canonical"]',
]

up.modal.config.template = (options) => {
  return `<div class="up-modal">
    <div class="up-modal-backdrop">
    <div class="up-modal-viewport">
      <div class="up-modal-dialog modal-dialog">
        <div class="up-modal-content modal-content">
          <!-- the matching element will be placed here -->
        </div>
        <button type="button" class="up-modal-close" up-close title="Close"></button>
      </div>
    </div>
  </div>`
}

up.on('up:modal:opened', () => { document.body.classList.add('-modal-open') })
up.on('up:modal:close', () => { document.body.classList.remove('-modal-open') })

up.compiler('meta, link', (element) => {
  // Because of paranoia reasons, we remove up-source attributes on meta tags
  element.removeAttribute('up-source')
})
