import TomSelect from 'tom-select/dist/js/tom-select.base.js'
import { createPopper } from '@popperjs/core/lib/popper-lite'
import flip from '@popperjs/core/lib/modifiers/flip'
import preventOverflow from '@popperjs/core/lib/modifiers/preventOverflow'

up.compiler('[tom-select]', function(element, data) {
  basicSelect()

  function renderNoResults() {
    return '<div class="no-results">Keine Übereinstimmungen gefunden</div>'
  }

  function dropdownParent() {
    return element.closest('up-modal') || null
  }

  function basicSelect() {
    return buildTomSelect({
      render: {
        no_results: renderNoResults,
      },
    })
  }

  function buildTomSelect(options) {
    const tomSelect = new TomSelect(element, {
      ...options,
      create: false,
      dropdownParent: dropdownParent(),
      closeAfterSelect: true,
      sortField: [{ field: '$order' }, { field: '$score' }], // sort first by default order and then by relevance score
      onInitialize: function() {
        this.dropdown.style.margin = '0' // needed to remove popper.js warnings due to margin in the browser console
        this.popper = createPopper(this.control, this.dropdown, {
          modifiers: [flip, preventOverflow],
        })
      },
    })

    tomSelect.positionDropdown = function() {
      this.popper.update()
      this.dropdown.style.width = `${this.control.getBoundingClientRect().width}px`
    }

    tomSelect.on('destroy', function() {
      this.popper.destroy()
    })

    return tomSelect
  }

  return () => {
    element.tomselect.destroy()
  }
})
